/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmationModal from "./Modals/ConfirmationModal.component";
import Button from "./Buttons/Button.component";
import { useAppSelector } from "../../hooks/redux/hooks";
import useUserIsAdmin from "../../hooks/useUserIsAdmin";
import useUserIsSupervisor from "../../hooks/useUserIsSupervisor";
import CampaignModal from "./Modals/CampaignModal.component";
import useCampaigns from "../../hooks/features/useCampaigns";
import customToast from "../utils/customToast";
import { IToastType } from "../models/types/Toast.type";
import useAccessControl from "../../hooks/useAccessControl";
import { Permissions } from "../../slices/permissiongroups.slice";

const FooterPageInfo = ({
  back,
  save,
  remove,
  reactive,
  spam,
  toggleSidebar,
  sidebar,
  title,
  showDeleteModal,
  setShowDeleteModal,
  showSpamModal,
  setShowSpamModal,
  showReactiveModal,
  setShowReactiveModal,
  showStartModal,
  setShowStartModal,
  disabled,
  deleted,
  spammed,
  hideRemove,
  hideSave,
  hideReactive,
  hideStart,
}: {
  back?: any;
  save?: any;
  remove?: any;
  reactive?: any;
  spam?: any;
  // start?: any;
  toggleSidebar?: boolean;
  sidebar?: boolean;
  title?: string;
  showDeleteModal?: boolean;
  setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showReactiveModal?: boolean;
  setShowReactiveModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showSpamModal?: boolean;
  setShowSpamModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showStartModal?: boolean;
  setShowStartModal?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  deleted?: boolean;
  spammed?: boolean;
  hideRemove?: boolean;
  hideSave?: boolean;
  hideReactive?: boolean;
  hideStart?: boolean;
}) => {
  const { canAccess } = useAccessControl();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useUserIsAdmin();
  const { isSupervisor } = useUserIsSupervisor();
  const { template, footer } = useCampaigns();
  const { selectedOfficehour } = useAppSelector((state) => state.officehours);
  const { selectedLocalCampaign } = useAppSelector((state) => state.campaigns);
  const { selectedSettings } = useAppSelector((state) => state.settings);

  useEffect(() => {
    if (typeof selectedLocalCampaign?._id !== "undefined") {
      template.startDisabledCallback();
    }
  }, [template.startDisabledCallback]);

  const getBackButton = () => {
    return (
      <Button
        minWidth={false}
        label="Voltar"
        icon="las la-arrow-left"
        onClick={back}
        extraClass={`${toggleSidebar ? "md:ml-[252px]" : sidebar ? "" : "md:ml-20"
          }`}
        inverted
        hoverColor
      />
    );
  };

  const getSpamButton = () => {
    if (!canAccess(Permissions.MARK_CONTACTS_AS_SPAM) || !location.pathname.includes("settings/contact")) return null;
    if (!isAdmin && !isSupervisor) return null;
    return (
      <Button
        minWidth={false}
        extraClass="mr-4"
        inverted
        orange
        hoverColor
        label={spammed ? "Remover SPAM" : "Marcar como SPAM"}
        icon="las la-exclamation-circle"
        onClick={() => {
          if (setShowSpamModal) setShowSpamModal(true);
        }}
      />
    );
  };

  const getStartCampaignButton = () => {
    if (
      !canAccess(Permissions.START_CAMPAIGNS) ||
      location.pathname.includes("/batches") ||
      !location.pathname.includes("/campaign/") ||
      (!isAdmin && !isSupervisor) ||
      selectedLocalCampaign?.deleted
    ) {
      return null;
    }
    return (
      <Button
        minWidth={false}
        extraClass={
          location.pathname.includes("/batches") ? "md:ml-20" : "mr-4"
        }
        inverted
        hoverColor
        icon="las la-play"
        label="Iniciar campanha"
        onClick={() => {
          if (
            typeof selectedLocalCampaign?.user?._id === "undefined" &&
            typeof selectedSettings?.defaultagent?._id === "undefined"
          ) {
            customToast({
              type: IToastType.WARNING,
              message:
                "Para campanhas sem agente definido, selecione antes o agente padrão de ações anônimas do sistema.",
            });
            navigate("/settings/general#settings-defaultagent");
          } else if (setShowStartModal) setShowStartModal(true);
        }}
        disabled={footer.startDisabled}
      />
    );
  };

  const getReactiveRemoveButton = () => {
    if (
      !!remove &&
      !deleted &&
      location.pathname !== "/settings" &&
      !location.hash.includes("#infos") &&
      !location.pathname.includes("dashboards") &&
      !location.pathname.includes("integrations") &&
      !location.pathname.includes("profile") &&
      !location.pathname.includes("general") &&
      !location.pathname.includes("surveyresults") &&
      !location.pathname.includes("/batches") &&
      !selectedOfficehour?.default
    ) {
      return (
        <Button
          minWidth={false}
          label="Remover"
          icon="las la-trash"
          onClick={() => {
            if (setShowDeleteModal) setShowDeleteModal(true);
          }}
          extraClass="md:ml-20 mr-4"
          inverted
          red
          hoverColor
        />
      );
    }

    if (
      !selectedOfficehour?.default &&
      !location.pathname.includes("/batches") &&
      reactive &&
      isAdmin
    ) {
      return (
        <Button
          minWidth={false}
          label="Reativar"
          icon="las la-user-check"
          onClick={() => {
            if (setShowReactiveModal) setShowReactiveModal(true);
          }}
          extraClass="md:ml-20 mr-4"
          inverted
          green
          hoverColor
        />
      );
    }
    return null;
  };

  const getSaveButton = () => {
    if (save) {
      return (
        <Button
          minWidth={false}
          disabled={disabled}
          hoverColor
          label="Salvar"
          icon="las la-save"
          onClick={save}
        />
      );
    }
    return null;
  };

  return (
    <div className="absolute shadow-md w-full flex items-center justify-between py-2 px-6 bottom-0 left-0 right-0 bg-footer z-10">
      {getBackButton()}
      <div>
        {getReactiveRemoveButton()}
        {getSpamButton()}
        {getStartCampaignButton()}
        {getSaveButton()}
      </div>
      {!!remove && showDeleteModal && setShowDeleteModal && title ? (
        <ConfirmationModal
          disabled={false}
          action={remove}
          setShowModal={setShowDeleteModal}
          title={title}
        />
      ) : null}
      {showSpamModal && setShowSpamModal && title ? (
        <ConfirmationModal
          disabled={false}
          action={spam}
          setShowModal={setShowSpamModal}
          content={
            title === "Deseja marcar este contato como SPAM?"
              ? "<label class='ml-12 text-[14px] font-normal'>As conversas deste contato serão canceladas.</label>"
              : undefined
          }
          title={title}
        />
      ) : null}
      {showReactiveModal && setShowReactiveModal && reactive && title ? (
        <ConfirmationModal
          disabled={false}
          action={reactive}
          setShowModal={setShowReactiveModal}
          title={title}
        />
      ) : null}
      {canAccess(Permissions.START_CAMPAIGNS) && showStartModal && setShowStartModal ? (
        <CampaignModal setShowModal={setShowStartModal} />
      ) : null}
    </div>
  );
};

FooterPageInfo.defaultProps = {
  title: "",
  back: undefined,
  save: undefined,
  remove: undefined,
  toggleSidebar: undefined,
  reactive: undefined,
  spam: undefined,
  showDeleteModal: false,
  setShowDeleteModal: null,
  showReactiveModal: false,
  setShowReactiveModal: null,
  showStartModal: false,
  setShowStartModal: null,
  showSpamModal: false,
  setShowSpamModal: null,
  disabled: false,
  deleted: false,
  spammed: false,
  sidebar: false,
  hideRemove: false,
  hideSave: false,
  hideReactive: false,
  hideStart: false,
};

export default FooterPageInfo;
